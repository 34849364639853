<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix">
      <h3 class="inBlock">课程分类</h3>
    </div>
    <div class="box">
      <div class="titles">
        <el-button size="small" type="primary" @click="dialogNewsCate = true"
          >添加分类</el-button
        >
        <div class="ipts">
          <p>
            <span>类型名称:</span
            ><input
              v-model="listForm.name"
              placeholder="请输入名称"
              type="text"
            />
          </p>
        </div>
      </div>
      <div class="tables">
		<el-table
		  row-key="id"
		  :tree-props="tableprops"
		  border
		  :data="list"
		  :row-style="{ height: '30px', padding: '0' }"
		  default-expand-all
		>
          <el-table-column prop="id" label="分类id"> </el-table-column>
          <el-table-column prop="name" label="分类名称"> </el-table-column>
          <el-table-column label="操作">
            <div slot-scope="scope">
              <el-button
                size="mini"
                round
                type="primary"
                @click="bianji(scope.row)"
                >编辑</el-button
              >
              <el-button
                size="mini"
                round
                type="danger"
                @click="deleteCate(scope.row.id)"
                >删除</el-button
              >
            </div>
          </el-table-column>
		</el-table>  
		  
<!--        <el-table :data="list" border stripe>
	
          <el-table-column prop="id" label="分类id"> </el-table-column>
          <el-table-column prop="name" label="分类名称"> </el-table-column>
          <el-table-column label="操作">
            <div slot-scope="scope">
              <el-button
                size="mini"
                round
                type="primary"
                @click="bianji(scope.row)"
                >编辑</el-button
              >
              <el-button
                size="mini"
                round
                type="danger"
                @click="deleteCate(scope.row.id)"
                >删除</el-button
              >
            </div>
          </el-table-column>
        </el-table> -->
        <el-pagination
          style="margin-top: 20px"
          @current-change="cateListCrtChg"
          :current-page="listForm.page"
          layout="prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>

    <!-- 添加编辑类型 -->
    <el-dialog title="课程分类" width="30%" :visible.sync="dialogNewsCate">
      <el-form :rules="rules"  label-width="80px" :model="newsCateForm" ref="addnewscate">
 
		<el-form-item label="父级:" >
			<div class="block">
			  <el-cascader
				style="width: 100%;"
				clearable
			    :props="optionProps"
			    :options="options"
			    @change="setclass"
				v-model="newsCateForm.parent_id"
			    ref="cascader"
			  ></el-cascader>
			</div>
		</el-form-item>  

        <el-form-item label="名称:" prop="name">
          <el-input
            v-model="newsCateForm.name"
            placeholder="请输入分类名称"
          ></el-input>
        </el-form-item>
		<el-form-item v-if="newsCateForm.parent_id!=''" label="分类类型:" prop="name">
		    <el-radio v-model="newsCateForm.type" :label="1">图文</el-radio>
		    <el-radio v-model="newsCateForm.type" :label="2">音视频</el-radio>
		</el-form-item>
		
        <el-form-item prop="weigh" label="排序:">
          <el-input
            v-model="newsCateForm.sort"
            placeholder="请输入分类排序"
          ></el-input>
        </el-form-item>
		<el-form-item label="分类图标">
		  <el-upload
		    class="avatar-uploader"
		    :action="serverUrl"
			name='image'
		    :show-file-list="false"
		    :on-success="handleAvatarSuccess"
			:headers="header" 
			:data="data"
		    :before-upload="beforeAvatarUpload">
		    <img v-if="newsCateForm.pic_url" :src="imgurl+ newsCateForm.pic_url" class="avatar">
		    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
		  </el-upload>
		  <!-- <el-upload class="avatar-uploader-editor" :action="serverUrl" name="image" :headers="header" :data="data" :show-file-list="false"
		  :on-success="uploadSuccess" :before-upload="be_up" :on-error="uploadError"> </el-upload> -->
		</el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogNewsCate = false">取 消</el-button>
        <el-button type="primary" @click="addNewsCategory">确 定</el-button>
      </div>
    </el-dialog>
  </el-card>
</template>

<script>
import request from "@/api/http.js";
import {
  classifyList,
  classifyAdd,
  classifyDetails,
  classifyDelete
} from "@/api/api.js";
import {getSystemsettings} from '../../api/getSystemsetting.js'
export default {
  components: {},
  name: "",
  data() {
    return {
		header:{AdminToken:localStorage.getItem('admin_token')},
		serverUrl: request.defaults.baseURL+'/backend/uploadsfile/upload_image', //上传的图片服务器地址
		data:{
			type:'addnews'
			
		},
	tableprops: {
	  children: "children",
	},	
	optionProps: {
	  value: "id",
	  label: "name",
	  children: "children",
	  checkStrictly: true,
	},
	imgurl:'',
	options:[],
      listForm: {
        name: "",
        page: 1,
        limit: 10,
      },
      total: 1,
      list: [],

      //  添加编辑分类
      dialogNewsCate: false,
      newsCateForm: {
        name: "",
		parent_id:'',
		sort:'',
		pic_url:'',
		type:0,
      },
	  id:'',
      rules: {
        name: [{ required: true, message: "请输入分类名称", trigger: "blur" }]
      },
    };
  },
  watch: {
    dialogNewsCate: function (newData, oldData) {
      if (!newData) {
        delete this.newsCateForm.id;
        this.newsCateForm.name = "";
      }
    },
  },
  methods: {
	  handleAvatarSuccess(res,file){
	  	console.log(res,file)
	  	// this.imageUrl=res.data.imgurl
	  	this.newsCateForm.pic_url=res.data.imgurl
	  },
	  beforeAvatarUpload(){},
	setclass(e){
		console.log(e)
		this.newsCateForm.parent_id=e[e.length-1]
		console.log(this.newsCateForm.parent_id)
	},
    // 删除新闻分类
    deleteCate(id) {
      this.$confirm("真的要删除该分类吗?, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
          const { data } = await classifyDelete({ id });
          if (data.code != 200) return this.$message.error(data.data);
          // this.list.forEach((item) => {
          //   if (item.id == id) {
          //     let index = this.list.indexOf(item);
          //     this.list.splice(index, 1);
          //   }
          // });
		  this.getlist()
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    async bianji(item) {
        const { data } = await classifyDetails({ id: item.id });
        console.log(data.data)
		this.newsCateForm.parent_id=data.data.info.parent_id
        this.newsCateForm.id = data.data.info.id;
        this.newsCateForm.name = data.data.info.name;
		this.newsCateForm.pic_url = data.data.info.pic_url
		this.newsCateForm.type = data.data.info.type
        this.newsCateForm.sort = data.data.info.sort;
        this.dialogNewsCate = true;
      
    },
    
    // 添加新闻分类
    addNewsCategory() {
      this.$refs["addnewscate"].validate(async (valid) => {
        if (valid) {
          const { data } = await classifyAdd(this.newsCateForm);
          if (data.code != 200) return this.$message.error(data.data);
          this.$message.success(data.data);
          this.dialogNewsCate = false;
          this.getlist();
        } else {
          this.$message.warning("请检查填写内容!");
          return false;
        }
      });
    },

    // 获取新闻分类列表
    async getlist() {
        const { data } = await classifyList(this.listForm);
		console.log(data)
        if (data.code != 200) return this.$message.error(data.data);
        this.list = data.data.classify_list;
		this.options=data.data.classify_list;
    },

    cateListCrtChg(e) {
      this.listForm.page = e;
      this.getlist();
    },

  },
  created() {
	getSystemsettings.then(res=>{
		this.imgurl=res
	})  
	  
    this.getlist();
  },
};
</script>

<style  lang='less'>
.titles {
  background-color: rgb(241, 243, 244);
  padding: 10px;
  p {
    font-size: 14px;
    margin: 10px 0;
    span {
      margin-right: 5px;
    }
    input {
      height: 30px;
    }
  }
}
.tables {
  margin: 20px;
}
.el-dialog {
  .el-input {
    width: 50%;
  }
}
.xiugai {
    color: #999;
    border-bottom:  1px solid #999;
    margin-left: 12px;
}
	.edit_container .ql-editing{
		left: 0 !important;
	}
	  .avatar-uploader .el-upload {
	    border: 1px dashed #d9d9d9;
	    border-radius: 6px;
	    cursor: pointer;
	    position: relative;
	    overflow: hidden;
	  }
	  .avatar-uploader .el-upload:hover {
	    border-color: #409EFF;
	  }
	  .avatar-uploader-icon {
	    font-size: 28px;
	    color: #8c939d;
	    width: 178px;
	    height: 178px;
	    line-height: 178px;
	    text-align: center;
	  }
	  .avatar {
	    width: 178px;
	    height: 178px;
	    display: block;
	  }
</style>